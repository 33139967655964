.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh); /* calculate height of the screen minus the heading */
  }
  
  .map {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* .geocoding {
    position: absolute;
    top: 10px;
    left: 10px;
  } */
  


/* .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
} */

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 20px;
  border-radius: 8px;
  }

  .loader-sidebar {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    /* background-color: rgba(255, 255, 255, 0.8); */
    padding: 20px;
    border-radius: 8px;
    }
  
  .globe-icon {
	font-size: 30px;
	animation: rotate 2s linear infinite;
	color: #3983e0; /* Optional: set the color of the globe */
  }
  .globe-icon-sidebar {
    font-size: 30px;
    animation: rotate 2s linear infinite;
    color: #d6dae0; /* Optional: set the color of the globe */
    }
  
  @keyframes rotate {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }

  .loader-progress {
    position: absolute;
    top: 50%;
    left: 35%;
    width: 500px;
    background-color:azure;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    border-radius: 20px;
    z-index: 9999;

    }